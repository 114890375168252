import React from 'react';
import { Card, Col, Row, Container } from 'reactstrap';
import SocialLinks from '../components/SocialLinks';
import glenface from '../assets/img/icons/common/glenface.png';

function GithubProfileCard({ profile }) {
  //RETURN
  return (
    <Card className='section-lg bg-gradient-info shadow-lg border-0'>
      <Container className=''>
        <div className='p-2'>
          <Row className=''>
            <Col className='order-lg-2' lg='4'>
              <img
                src={glenface}
                style={{ width: '200px' }}
                alt=''
                className='rounded-circle img-center img-fluid shadow shadow-lg--hover mb-4'
              />
            </Col>
            <Col lg='8' className='order-lg-1'>
              <h2 className='text-white'>Send me a message!</h2>
              <h4>
                Got a question or proposal, or just want to say hello? Go ahead.
              </h4>
              <p className='text-white mt-3'>{profile.bio}</p>
              {/* <div className='my-3 icon-shape bg-gradient-white shadow rounded text-info'>
                <i className='ni ni-pin-3 text-info mr-2' />
                {profile.location}
              </div> */}
              <SocialLinks />
            </Col>
          </Row>
        </div>
      </Container>
    </Card>
  );
}

export default GithubProfileCard;
