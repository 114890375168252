import React, { Fragment } from 'react';
import { Fade } from 'react-reveal';
import DisplayLottie from '../components/DisplayLottie';
import webdev from '../assets/lottie/animation2.json';
import { Container, Row, Col, UncontrolledTooltip } from 'reactstrap';
import { skillsSection } from '../portfolio';

function Skills() {
  //RETURN
  return (
    <Fade left duration={1000} distance='40px'>
      <Container className='text-center my-5 section section-lg'>
        <h1 className='h1'>{skillsSection.title}</h1>
        <p className='lead'>{skillsSection.subTitle}</p>
        <Row>
          <Col lg='6'>
            <DisplayLottie animationData={webdev} />
          </Col>
          <Col lg='6'>
            <div className='d-flex justify-content-center flex-wrap mb-5'>
              {skillsSection.softwareSkills.map((el) => {
                return (
                  <Fragment key={el.skillName}>
                    <div
                      className='icon icon-lg icon-shape shadow rounded-circle mb-5'
                      id={el.skillName}
                    >
                      <span
                        className='iconify'
                        data-icon={el.fontAwesomeClassname}
                        data-inline='false'
                      ></span>
                    </div>
                    <UncontrolledTooltip
                      delay={0}
                      placement='bottom'
                      target={el.skillName}
                    >
                      {el.skillName}
                    </UncontrolledTooltip>
                  </Fragment>
                );
              })}
            </div>
            <div>
              {skillsSection.skills.map((el) => {
                return <p key={el}>{el}</p>;
              })}
            </div>
          </Col>
        </Row>
      </Container>
    </Fade>
  );
}

export default Skills;
