import React, { useState, useEffect, Suspense } from 'react';
import Loading from '../components/Loading';
import { githubUserName } from '../portfolio';
import get from 'axios';
import GithubProfileCard from 'components/GithubProfileCard';

const url = `https://api.github.com/users/${githubUserName}`;

function GithubProfile() {
  //useState
  const [profile, setProfile] = useState({});

  // FUNCTION profile data
  const getProfileData = async () => {
    try {
      const response = await fetch(url);
      const data = await response.json();
      setProfile(data);
    } catch (error) {
      console.log(error);
    }
  };

  //useEffect
  useEffect(() => {
    getProfileData();
  }, []);

  //RETURN
  return (
    <Suspense fallback={<Loading />}>
      <GithubProfileCard profile={profile} />
    </Suspense>
  );
}

export default GithubProfile;
